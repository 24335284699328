import * as React from "react"
import SideBox from "../../components/SideBox"
import * as config from "../../constants"
import { Affiliate, AffiliateProps } from "../Affiliate"
import "./style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare, faRssSquare, faS } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin, faStackOverflow, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'


export const SideBar: React.FunctionComponent<SideBarProps> = ({ tags = ["misc"], affiliateLimit = 2, sideBarClosed = false }) => {
  const [sideBarClosedState, setSideBarClosedState] = React.useState(sideBarClosed);
  const toggleSideBar = React.useCallback(() => { setSideBarClosedState(!sideBarClosedState) }, [sideBarClosedState]);

  return (
    <>
      <div className={`${sideBarClosedState ? 'lg:w-4 overflow-hidden delay-100' : 'lg:w-4/12'}  mt-10 mr-6 ml-3 text-gray-800 dark:text-gray-300 relative`}>
        <div onClick={toggleSideBar} className="text-right hidden lg:block absolute right-0 z-50">
          <span className={`text-right ${sideBarClosedState ? 'cursor-pointer opacity-100 transition-no-colors' : 'opacity-0'} expand-side-bar`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline -mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 17l-5-5m0 0l5-5m-5 5h12" />
            </svg>
          </span>
          <span onClick={toggleSideBar} className={` text-right ${!sideBarClosedState ? 'cursor-pointer opacity-100 transition-no-colors' : 'opacity-0'} expand-side-bar`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </span>
        </div>
        <div className={`${sideBarClosedState ? 'opacity-0' : 'opacity-100 '} transition-no-colors duration-500 `}>
          <div className={`${sideBarClosedState ? 'translate-x-full' : ''} transition-no-colors duration-500 transform`}>
            <SideBox className="text-3xl mb-12" title="Let's Connect">
              <a href="/rss.xml" className="mr-2 inline-block dark:text-cyan-500" title="RSS Feed">
                <FontAwesomeIcon icon={faRssSquare} />
              </a>
              <a href={`https://twitter.com/${config.TWITTER}`} className="mr-2 inline-block dark:text-cyan-500" title="Find me on Twitter">
                <FontAwesomeIcon icon={faTwitterSquare} />
              </a>
              <a href={`https://github.com/${config.GITHUB}`} className="mr-2 inline-block" title="Find me on GitHub">
                <span className="fa-layers">
                  <FontAwesomeIcon icon={faSquare} className="dark:text-cyan-500" />
                  <FontAwesomeIcon icon={faGithub} inverse={true} size="xs" className="dark:text-gray-800" />
                </span>
              </a>
              <a href={`https://linkedin.com/in/${config.LINKEDIN}`} className="mr-2 inline-block dark:text-cyan-500" title="Find me on LinkedIn">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href={`https://stackoverflow.com/users/1824367/${config.STACKOVERFLOW}`} className="inline-block" title="Find me on Stack Overflow">
                <span className="fa-layers">
                  <FontAwesomeIcon icon={faSquare} className="dark:text-cyan-500" />
                  <FontAwesomeIcon icon={faStackOverflow} inverse={true} className="dark:text-gray-800" size="xs" />
                </span>
              </a>
            </SideBox>
            <Affiliate tags={tags} affiliateLimit={affiliateLimit} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SideBar
export interface SideBarProps {
  tags?: string[]
  affiliateLimit?: number
  sideBarClosed?: boolean
}

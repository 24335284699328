import * as React from "react"
import SideBox from "../../components/SideBox"
import * as config from "../../constants"
import { shuffle } from "lodash"

export const Affiliate: React.SFC<AffiliateProps> = ({ tags = ["misc"], affiliateLimit = 3 }) => (
  <SideBox title="Affiliate Sponsors" className="sponsor" content={<div dangerouslySetInnerHTML={{ __html: getAffiliates(tags, affiliateLimit) }} />} />
)

export const getAffiliates = (articleTags: string[], limit: number) => {

  const todaysDate = new Date()
  const promoStartDate = new Date(2022, 9, 10, 0, 0, 0, 0)
  const promoEndDate = new Date(2022, 9, 17, 0, 0, 0, 0)
  if (todaysDate > promoStartDate && todaysDate < promoEndDate) {
    return `
    <a href="https://pluralsight.pxf.io/i/1197267/1473341/7490" target="_top" id="1164892" style="text-align:center;display:block">
      <img src="/img/pluralsight-promo.png" />
    </a>
    <br />    
    <a href="https://pluralsight.pxf.io/i/1197267/1473341/7490" target="_top" id="1164892">Pluralsight</a> is free all week long! It's one of the best & biggest sources of quality video content. Plus they have quizzes to help you validate where you're at and what you've learned.

      <img height="0" width="0" src="https://pluralsight.pxf.io/i/1197267/1473341/7490" style="position:absolute;visibility:hidden;" border="0" />
    `
  }

  const promoStartDate2 = new Date(2020, 7, 16, 20, 0, 0, 1)
  const promoEndDate2 = new Date(2020, 11, 28, 20, 0, 0, 0)
  if (todaysDate > promoStartDate2 && todaysDate < promoEndDate2) {
    return `
    <a target="_blank" href="https://amzn.to/38Pfsdw" style="text-align:center;display:block">
      <img src="/img/audible-promo.png" />
    </a>
    <br />
    
    <p>If you haven't tried Audiobooks yet you're missing out on one of my favorite learning tools! I rarely have time to sit down & read a book but I can find time to listen to books at the gym, driving, walking my dogs & doing work around my home.</p>
    
    <p><a target="_blank" href="https://amzn.to/38Pfsdw">Try Audible Premium and Get Two Free Audiobooks to keep</a> or <a href="https://amzn.to/35ym9i0" target="_blank">try Audible Plus and stream unlimited books for less than $5 a month!</a></p>
    
    <p>If you don't like it, get rid of it. If you get addicted, you're welcome! Check out my list of <a href="/favorite-books">favorite books</a> for some recommendations!</p>
    <p>It makes a great Christmas gift too!</p>

    `
  }

  const matchedAffiliates = getMatchingAffiliates(articleTags)
  const uniqueAffiliates = filterUniqueAffiliates(matchedAffiliates)
  const limitAffiliates = uniqueAffiliates.slice(0, limit)
  const shuffledAffiliates = shuffle(limitAffiliates)
  const reduceSimilarAffiliates = filterAndReduceSimilarAffiliates(shuffledAffiliates)

  return reduceSimilarAffiliates
    .map((affiliate) => `<div class="text-xl">${affiliate.name}</div><div class="mb-8">${affiliate.text}</div>`)
    .join("")
    + amazonAssociateText
}

export const getMatchingAffiliates = (articleTags: string[]) => {
  const allAffiliates = config.AFFILIATELIST
  const affiliatesToShow = articleTags.map(
    (articleTag) => allAffiliates.filter(
      (affiliate) => affiliate.tags.find((affiliateTag) => affiliateTag === articleTag)
    )
  )
  return flattenArrays(affiliatesToShow) as config.AffiliateListItem[]
}

const filterAndReduceSimilarAffiliates = (affiliates: config.AffiliateListItem[]) => {
  const categories: string[] = []
  return affiliates.filter((affiliate, index) => {
    if (categories.find((c) => c === affiliate.type)) {
      return false
    } else {
      categories.push(affiliate.type)
      return true
    }
  })
}

const filterUniqueAffiliates = (affiliates: config.AffiliateListItem[]) => (
  affiliates.filter((affiliate, index) =>
    affiliates.findIndex((v, i) => v.name === affiliate.name) === index,
  )
)

const flattenArrays = (array: config.AffiliateListItem[][]) => [].concat(...array) as config.AffiliateListItem[]

export const amazonAssociateText = `<div><hr class="my-8" /><p>On occassion I do link to a product available on Amazon. As an Amazon Associate I earn from qualifying purchases.</p></div>`

export default Affiliate

export interface AffiliateProps {
  tags?: string[]
  affiliateLimit?: number
}
